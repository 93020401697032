import React, { FC } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { TextField, SelectField } from '../../../../components';
import { ContactUsFormState } from '../../../../types/forms/contactUsForm';
import { contactRoles } from '../../../../types/forms/common';

const ContactDetailsFormFields: FC = () => {
  const { values } = useFormikContext();

  const showRoleDescription = (values as ContactUsFormState).role === 'OTHER';

  return (
    <fieldset
      className={classNames('vads-l-grid-container', 'vads-u-padding-x--0', 'contact-details')}
    >
      <legend>
        <h2 className={classNames('vads-u-font-size--lg', 'vads-u-margin-y--0')}>
          Tell us about you
        </h2>
      </legend>
      <div className={classNames('vads-u-margin-top--2p5')}>
        <TextField label="First name" name="firstName" required />
      </div>
      <div className={classNames('vads-u-margin-top--4')}>
        <TextField label="Last name" name="lastName" required />
      </div>
      <div className={classNames('vads-u-margin-top--4')}>
        <TextField label="Email address" name="email" type="email" required />
      </div>
      <div className={classNames('vads-u-margin-top--4')}>
        <TextField label="Organization" name="organization" />
      </div>
      <div className={classNames('vads-u-margin-top--4')}>
        <SelectField label="Role" name="role" required>
          <option value="">- Select -</option>
          {Array.from(contactRoles).map(([key, value]) => (
            <option key={key} value={key}>{value}</option>
          ))}
        </SelectField>
      </div>
      {showRoleDescription && (
        <div className={classNames('vads-u-margin-top--4')}>
          <TextField
            label="Describe role"
            name="roleDescription"
            className={classNames('vads-u-padding-top--2', 'indented')}
            required
          />
        </div>
      )}
    </fieldset>
  );
};

export default ContactDetailsFormFields;
