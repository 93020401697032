import { VaAlert } from '@department-of-veterans-affairs/component-library/dist/react-bindings';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { ApiAlerts, PageHeader } from '../../components';
import { LPB_APPLY_URL } from '../../types/constants';
import { ApplySuccessResult } from '../../types/forms/apply';
import { SUPPORT_CONTACT_PATH, TERMS_OF_SERVICE_PATH } from '../../types/constants/paths';
import { allowSandboxForm, isApiAuthTypeSandboxBlocked } from '../../utils/restrictedAccessHelper';
import { APIDescription } from '../../apiDefs/schema';
import { getApi } from './DocumentationRoot';
import { SandboxAccessSuccess } from './components/sandbox';
import './RequestSandboxAccess.scss';
import { SandboxAccessForm } from './components/sandbox/SandboxAccessForm';

const sandboxFormAuthTypes = (api: APIDescription): string[] => {
  const authTypes = [];
  if (api.apikey && !isApiAuthTypeSandboxBlocked(api, 'apikey')) {
    authTypes.push('apikey');
  }
  if (api.oAuthInfo?.acgInfo && !isApiAuthTypeSandboxBlocked(api, 'acg')) {
    authTypes.push('acg');
  }
  if (api.oAuthInfo?.ccgInfo && !isApiAuthTypeSandboxBlocked(api, 'ccg')) {
    authTypes.push('ccg');
  }
  return authTypes;
};

const RequestSandboxAccess = (): JSX.Element => {
  const { urlSlug } = useParams();
  const api = getApi(urlSlug);
  const [successResults, setSuccessResults] = useState<ApplySuccessResult | false>(false);

  if (!api || !allowSandboxForm(api)) {
    throw new Error('API does not allow sandbox signups via the public portal');
  }

  const authTypes = sandboxFormAuthTypes(api);

  return (
    <>
      <Helmet>
        {successResults ? (
          <title>Your submission was successful.</title>
        ) : (
          <title>{api.name} Sandbox Access</title>
        )}
      </Helmet>
      <ApiAlerts />
      <PageHeader
        header={successResults ? 'Success, happy developing!' : 'Sandbox access'}
        subText={successResults ? '' : api.name}
      />
      {successResults ? (
        <SandboxAccessSuccess result={successResults} api={api} />
      ) : (
        <>
          <p className="vads-u-margin-top--3">
            Submit this form to get instant access to test data for this API.
          </p>
          {api.altID && authTypes.length > 0 ? (
            <SandboxAccessForm
              api={api}
              authTypes={authTypes}
              onSuccess={setSuccessResults}
              urls={{
                postUrl: LPB_APPLY_URL,
                termsOfServiceUrl: TERMS_OF_SERVICE_PATH,
              }}
              key={api.urlFragment}
            />
          ) : (
            <VaAlert status="error" visible uswds>
              <p>
                There is an error with this Sandbox Access Form. Please{' '}
                <Link to={SUPPORT_CONTACT_PATH}>contact support</Link> for additional assistance.
              </p>
            </VaAlert>
          )}
        </>
      )}
    </>
  );
};

export default RequestSandboxAccess;
